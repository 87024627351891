import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { useSiteMetaData } from '../hooks/useSiteMetadata'
import { styled } from '../shared/theme'

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

const Title = styled.h1`
  position: relative;
  margin: auto;
  font-family: 'Lobster', cursive;
  font-size: 4em;
`

export default function IndexPage() {
  const siteMetadata = useSiteMetaData()
  return (
    <Layout>
      <SEO
        isIndex={true}
        title="Code Writer"
        description={siteMetadata.description}
      />
      <Section>
        <Title>{siteMetadata.organization.name}</Title>
      </Section>
    </Layout>
  )
}
